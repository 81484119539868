@import '../../../../vendor/mdui/variable.less';

#page-topic .contexts,
#page-user .contexts {
  margin-top: 16px;

  .mc-list-header {
    padding: 16px;

    .title {
      font-size: 16px;
    }

    button {
      padding-right: 0;
    }
  }

  .item-list {
    border-top: 1px solid rgba(0, 0, 0, .12);

    .mdui-theme-layout-dark & {
      border-top-color: rgba(255, 255, 255, .12);
    }
  }

  @media (max-width: @screen-xs-max) {
    border-right: none;
    border-left: none;
    border-radius: 0;

    .mc-list-header {
      .title {
        font-size: 15px;
      }
    }
  }

  .items-wrapper {
    margin-right: 0;
    margin-left: 0;

    &::after {
      display: table;
      clear: both;
      content: "";
    }

    @media (min-width: @screen-sm-min) {
      margin-right: -8px;
      margin-left: -8px;
    }
  }

  .item-inner {
    position: relative;
    float: left;
    box-sizing: border-box;
    width: 50%;

    &:nth-child(odd) {
      padding-right: 4px;
      padding-left: 8px;
    }

    &:nth-child(even) {
      padding-right: 8px;
      padding-left: 4px;
    }

    @media (min-width: @screen-sm-min) {
      width: 33.333333%;
      padding-right: 8px !important;
      padding-left: 8px !important;
    }

    @media (min-width: @screen-md-min) {
      width: 25%;
    }
  }

  .mc-follow {
    min-width: 0;
    padding: 0 8px;
  }

  .mc-loading {
    margin-bottom: -62px;
  }
}
