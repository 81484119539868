@import "../../vendor/mdui/variable.less";

.mc-list-item {
  position: relative;
  display: block;
  padding: 16px 24px 16px 64px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: background-color .18s cubic-bezier(.4,0,.2,1);

  .mdui-theme-layout-dark & {
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: @color-hover-black;

    .mdui-theme-layout-dark & {
      background-color: @color-hover-white;
    }
  }

  &:active {
    background-color: #eee;

    .mdui-theme-layout-dark & {
      background-color: #484848;
    }
  }

  &.last-visit::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: @primaryColor;
    content: ' ';

    .mdui-theme-layout-dark & {
      background-color: rgba(255, 255, 255, .7);
    }
  }

  .mc-user-popover {
    position: absolute;
    left: 26px;
    width: 20px;
    height: 20px;

    &>.avatar {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
      border-radius: 50%;
    }
  }

  .title {
    height: 20px;
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: @screen-xs-max) {
      font-size: 15px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    height: 16px;
    margin-top: 8px;
  }

  .snippet {
    flex: 1;
    overflow: hidden;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .meta {
    display: flex;
    padding-left: 48px;
    font-size: 13px;
  }

  .update_time {
    display: none;
  }

  .replys {
    margin-left: 16px;
  }

  &:hover {
    .meta {
      padding-left: 12px;
    }

    .update_time {
      display: block;
    }
  }

  @media (max-width: @screen-xs-max) {
    padding-left: 50px;

    .mc-user-popover {
      left: 16px;
    }

    .snippet {
      display: none;
    }

    .meta {
      flex-direction: row-reverse;
      padding-left: 0 !important;
    }

    .update_time {
      display: block;
    }

    .replys {
      margin-right: 16px;
      margin-left: 0;
    }
  }
}

.mc-article-item {
  .item {
      margin-bottom: 16px;
      background-repeat: no-repeat;
      background-size: cover;
      

      

      @media (max-width: @screen-xs-max) {
          margin-bottom: 8px;
      }

      .info {
          display: block;
          box-sizing: border-box;
          // padding: 120% 16px 0;
          text-decoration: none;
          border-radius:10px;
          padding-bottom: 150%;

          .item-image {
            position: absolute;
            width: 100%;
            // height: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 10px;
          }
      }

      .name {
          padding-top: 16px;
          padding-bottom: 8px;
          overflow: hidden;
          font-size: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
      }

      .actions {
          display: flex;
          justify-content: space-between;
          padding: 3px 0 3px 16px;
          // border-radius: 10px;
          // border-top:solid 1px #666;
      }

      .followers {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
      }

      .custom_vote_btn{
        
      }

      .custom_vote_icon{
        color: #fff;
      }

      .custom_follow_btn{
        
      }

      .custom_follow_icon{
        color: #fff;
      }

      .custom_option_icon{
        color: #fff;
      }
       
      
  }
  .item::before {  
    content: "";  
    position: absolute;  
    top: 0;  
    left: 0;  
    width: 100%;  
    height: 100%;  
    background: linear-gradient(to right, rgba(0, 102, 255, 0.1), rgba(252, 56, 255, 0.1)); /* 更淡的冷色调渐变 */  
    z-index: -1;  
    border-radius: 10px;
  }  
  
  .item::after {  
    content: "";  
    position: absolute;  
    top: 0;  
    left: 0;  
    width: 100%;  
    height: 100%;  
    background: inherit;  
    filter: blur(20px); /* 更强的模糊滤镜 */  
    z-index: -2;  
  } 
  

  .item-inner {
      position: relative;
      float: left;
      box-sizing: border-box;
      width: 50%;

      &:nth-child(odd) {
          padding-right: 4px;
          padding-left: 8px;
      }

      &:nth-child(even) {
          padding-right: 8px;
          padding-left: 4px;
      }

      @media (min-width: @screen-sm-min) {
          width: 33.333333%;
          padding-right: 8px !important;
          padding-left: 8px !important;
      }

      @media (min-width: @screen-md-min) {
          width: 25%;
      }

      
  }

  .flex-grow-custom{
    flex-grow: 0.65;
  }

  .actions-custom{
    background-color: #00000075;
  }

  .mc-follow {
    min-width: 0;
    padding: 0 8px;
  }

  .mc-loading {
    margin-bottom: -62px;
  }
}


