.image-li{
    list-style-type: none;
    width: 100%;
    text-align: center;
}

.button {
    display: inline-block;
    width: 100px; // 设置按钮宽度
    height: 40px; // 设置按钮高度
    line-height: 40px; // 垂直居中按钮文字
    text-align: center; // 文字水平居中
    background-color: #007bff; // 按钮背景颜色
    color: #ffffff; // 文字颜色
    border: none; // 移除边框
    border-radius: 5px;
    margin: 10px 5px; // 设置按钮间距
    cursor: pointer; // 鼠标样式为手型
  }
  
  // 第一个按钮样式
  .button1 {
    .button;
  }
  
  // 第二个按钮样式
  .button2 {
    .button;
  }
  

.button-container {
    position: absolute; // 设置绝对定位
    width: 220px;
    bottom: 240px; // 距离底部的距离
    left: 50%; // 水平居中
    transform: translateX(-50%); // 使按钮水平居中
  }

.article-image{
    text-align: center;
}