@import "../../vendor/mdui/variable.less";

#page-article {
  max-width: 846px;

  &.mdui-container {
    padding-top: 0;
  }

  .article {
    padding: 0 64px;

    .title {
      position: relative;
      margin: 0;
      padding: 36px 0 24px;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
    }

    .content {
      padding: 48px 0 32px;
      text-align: center;
    }

    .mc-topics-bar {
      padding-bottom: 16px;
    }

    .actions {
      display: flex;
      margin-right: -64px;
      margin-left: -64px;
      padding: 8px 64px;
      background-color: rgba(0, 0, 0, .04);
    }

    .mc-follow {
      margin-left: 16px;
    }
  }

  @media (max-width: @screen-xs-max) {
    .article {
      padding: 0 16px;
      border-right: none;
      border-left: none;
      border-radius: 0;

      .title {
        padding: 16px 0;
        font-size: 22px;
        line-height: 32px;
      }

      .content {
        padding: 28px 0;
        text-align: center;
      }

      .mc-topics-bar {
        padding-bottom: 8px;
      }

      .actions {
        margin-right: -16px;
        margin-left: -16px;
        padding-right: 16px;
        padding-left: 16px;
      }
    }
  }
}
