@media (max-width: 599.9px) {
  #page-questions .item-list,
  #page-articles .item-list,
  #page-notifications .item-list {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-left: none;
    border-radius: 0;
    box-shadow: none;
  }
  .mdui-theme-layout-dark #page-questions .item-list,
  .mdui-theme-layout-dark #page-articles .item-list,
  .mdui-theme-layout-dark #page-notifications .item-list {
    border-top-color: rgba(255, 255, 255, 0.12);
    border-bottom-color: rgba(255, 255, 255, 0.12);
  }
}
#page-questions .mc-loading,
#page-articles .mc-loading,
#page-notifications .mc-loading {
  margin-bottom: -62px;
}
