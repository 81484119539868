@import "../../../../vendor/mdui/variable.less";

.mc-appbar .toolbar {
  position: relative;

  .headline,
  .title {
    overflow: visible;
    opacity: .87;
  }

  .headline {
    font-size: 24px;
    line-height: 38px;
    font-weight: 500;
    color: #ffffff;
    background-color: #004fbf;
    padding: 0 20px;
    border-radius: 12px;

    @media (max-width: @screen-xs-max) {
      overflow: hidden;
    }
  }

  .title {
    font-size: 20px;
    letter-spacing: .02em;

    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }

  .login,
  .register {
    min-width: 64px !important;
    margin: 0 24px 0 0 !important;

    @media (max-width: @screen-sm-max) {
      margin-right: 16px !important;
      padding: 0 12px;
    }

    @media (max-width: @screen-xs-max) {
      margin-right: 8px !important;
      padding: 0 8px;
    }
  }
}
